import Header from "../../Components/Header/Header";
import "./ServiceTimes.scss";

export default function ServiceTimes() {
  return (
    <div>
      <Header
        img={"./images/normalheader.jpg"}
        logo={"./images/HZ_SC_Logo.png"}
      />
      <div className="servicetimes-content">
        <img src={'images/stimes.jpg'} alt="Learn when the services start here at synergy church ministries!"></img>
      </div>
    </div>
  );
}
