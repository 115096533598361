import Header from "../../Components/Header/Header";
import styles from "./Worship.module.scss";
import { Link } from "react-router-dom";

export default function Worship() {
  return (
    <div>
      <Header video={"worship.mp4"} logo={"/images/SW_Horizontal.png"} />

      <div className={styles.worshipText}>
        <div className={styles.descFix}>
          <h3>Synergy Worship</h3>
          <p>
            The Synergy Worship team desires to create and maintain an
            environment where people can encounter God. We believe that praising
            & worshiping God brings change to every situation in our lives and
            draws us closer in our relationship with Him. Regardless of what
            life has thrown our way, we know that praise & worship provides
            victory and sustains us through any trial. We desire each worship
            experience to show that life is truly found in Jesus. When you step
            into a worship service, the overall tone you’ll find is one of
            celebration and joy, where we are holding nothing back in worship of
            our God. The energy level is high. The music is awesome. And our
            demonstration of love for our God is great.
          </p>

          <Link className={styles.BookBtn} to={"https://docs.google.com/forms/d/e/1FAIpQLSdvbmifYalDiwJfaXs5ItIYZzKHULk9JBVMuBRbyW8D9Bh37w/viewform?embedded=true"} target="_blank" referrerPolicy="no-referrer">Contact</Link>
        </div>

        <div className={styles.listenNow}>
            <h3>Listen</h3>
            <div className={styles.musicLinks}>
            <Link target="_blank" referrerPolicy="no-referrer" to={'https://www.youtube.com/@SynergyWorship'}>
                <img src="/images/yt.png" alt="Youtube link to listen to synergy worships music"></img>
            </Link>
            <Link target="_blank" referrerPolicy="no-referrer" to={'https://open.spotify.com/artist/6WtxlSuyQUAwqkYaCWuLhy?si=JCjAlfO0S6SaEIPyGUHoCg&nd=1&dlsi=a91ff0416da748af'}>
                <img src="/images/spot.png" alt="Spotify link to listen to synergy worships music"></img>
            </Link>
            <Link target="_blank" referrerPolicy="no-referrer" to={'https://music.apple.com/us/artist/synergy-worship/1497079133'}>
                <img src="/images/amusic.png" alt="Apple music link to listen to synergy worships music"></img>
            </Link>
            </div>
        </div>
      </div>
    </div>
  );
}
