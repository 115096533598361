import Header from "../../Components/Header/Header";
import ThreeWay from "../../Components/MinistryComponent/MinistryComponent";
import "./Kids.scss";

export default function Kids() {
  return (
    <div>
      <Header
        video={"kcamp.mp4"}
        logo={"./images/skidround.png"}
        isKids={true}
      />
      <ThreeWay
        pastorTitle={"Children's Pastor"}
        pastorName={"Nick Haas"}
        pastorImage={"/staff-images/Nick.jpg"}
        missionText={
          <>
            <p>Kids, birth through fifth grade, experience safe, age-appropriate environments where the Bible is taught in a creative & relevant way by our screened & trained volunteer leadership team. Kids will join the fun and experience a world where they can meet Jesus on their level! We strive to make every kids time here the best part of their week and with our positive & enthusiastic volunteers your kid won’t be able to wait for Sunday to get here!</p><p>It is never too early for a child to experience the love of Jesus. Our qualified volunteers create a positive church experience that introduces infants & toddlers to a love for Jesus early on. Your child can learn and play while you relax and enjoy your worship experience.</p><p>One thing we know here is preschoolers love to learn! This is why each of our preschool classrooms provide interactive learning experiences in a colorful, fun & engaging atmosphere for your child. From praise & worship to illustrated Bible lessons, your preschooler will begin to develop their personal relationship with their BEST friend Jesus in bite-size bits. To ensure the health of children, volunteers, and staff, we ask that sick children not attend class.</p> 
          </>
        }
        meetingImage={"/images/KidsMin.jpg"}
      />
    </div>
  );
}
