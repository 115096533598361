import Header from "../../Components/Header/Header";
import "./SmallGroups.scss";

export default function SmallGroups() {
  return (
    <div>
      <Header img={"./images/normalheader.jpg"} logo={"./images/HZ_SC_Logo.png"} />
      <div class="outreach-content">
        <div class="outeach-black-bg">
          <div class="outeach-text-inside">
            <p class="outeach-title">What are RealLife Groups?</p>
            <br/>
            <p>
            RealLife Groups are small groups of people who meet together on a regular basis. These groups provide a way for you to connect with others and feel a sense of belonging. People study God’s Word together and discuss the issues and challenges of life. It’s also where they pray, care for one another, and are missed if they don’t show up. They are drawn together by common interests, a specific topic or activity, or a particular demographic. In addition to offering a place of gathering for similar interests, our Home Groups also offer a place of community, building relationships with one another and spiritual growth.
            </p>
          </div>
        </div>

        <div class="outeach-text-inside">
          <p class="outeach-title">What should I expect when I go?</p>
          <p>
          Each Group is unique, however, you can expect a weekly gathering that involves food, conversation, prayer, and discussion for applying the Bible to practical living. RealLife Groups are an environment for building relationships with one another and strongly emphasize challenging one another to drive God’s Word deeply into our lives. Most Groups follow the Sunday sermon series; although some may choose to work through another book of the Bible.
          </p>
        </div>
      </div>
    </div>
  );
}
