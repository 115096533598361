import Header from "../../Components/Header/Header";
import AppStaff from "../../Components/StaffComponent/StaffComponent";
import "./Staff.scss";

export default function Staff() {
    return (
      <div>
        <Header
          img={"./images/Staff.png"}
          logo={"./images/HZ_SC_Logo.png"}
        />
        <AppStaff
          side={"Left"}
          name={"Dana & Nan Gammill"}
          title={"Senior Pastors"}
          img={"/staff-images/Pastor Dana_Nan_1.jpg"}
          desc={'He is Pastor Dana Gammill. He, along with his wife Nan, and their entire family and staff, are all committed to serving others. As the lead pastor, writer, business owner, consultant, life coach, and community leader, his heart is dedicated to blessing the community and reaching as many as possible for Christ. Growing up in a family of ministers, he has a deep connection to the church. He enjoys mentoring, inspiring, and motivating people to reach their full potential.'}
        />
        <AppStaff
          side={"Right"}
          name={"Michael & Jacee Gammill"}
          title={"Administrative Pastors"}
          img={"/staff-images/Pastor_Michael_Jacee.jpg"}
          desc={'Pastor Michael leads Sunday evening services at Synergy Canton. As a fourth-generation pastor, hes preached globally in Europe, South America, Asia, and Africa. With his wife Jacee, they engaged during missions in Zambia, married in 2017. Shes on the worship team and serves as the Graphic Designer. Together, they lead the Momentum college and career group for young adults and families. Since graduating from Oral Roberts University in 2010, hes been in ministry, particularly enjoying preparing couples for marriage through counseling. In 2021, a him and a friend started bussing young people from the inner city, expanding our outreach to challenging areas.'}
        />
        <AppStaff
          side={"Left"}
          name={"Deana Gammill Reed"}
          title={"Associate Pastor"}
          img={"/staff-images/Deanna.jpg"}
          desc={'An ordained and licensed minister, leads worship and offes support in hospital visitation ministry, officiating wedding ceremonies, funeral services, and grief support. Additionally, she speaks on a weekly livestream, reaching people worldwide.'}
        />
        <AppStaff
          side={"Right"}
          name={"Hunter and Allison Fowler"}
          title={"Youth Pastors"}
          img={"/staff-images/Hunter_Allison.jpg"}
          desc={'Hunter and Allison serve as our Worship and Youth Pastors. They were married in 2013 and have 3 children. They are gifted Songwriters and have released multiple Studio Albums including Worship and CHH Music. They regularly coordinate youth events, summer camps, and worship events. Hunter and Allison desire to see a “Firebreed Generation” that grabs hold of the Fathers heart and releases it with a fiery passion! Their dream is to provoke people to a place of deeper intimacy with the Lord.'}
        />



        <AppStaff
          side={"Left"}
          name={"Nick Haas"}
          title={"Childrens Pastor"}
          img={"/staff-images/Nick.jpg"}
          desc={'Mr. Nick loves hiking, biking, and kayaking with his family. His favorite movie is "Hook." Since 1994, he and his wife Laura have been in ministry together, teaching Gods Word to the kids in their community. They strongly believe in the importance of Kids Ministry for the future generation. Since October 2018, they have been proud members of Synergy Church, contributing to this incredible team of life changers.'}
        />
        <AppStaff
          side={"Right"}
          name={"Cynthia Stock"}
          title={"Inner Healing Minister"}
          img={"/staff-images/Cynthia.jpg"}
          desc={'Cynthia Stock has been married to Gary for 57 years and they have 7 children, 11 grandchildren, and have fostered 17 children. Cynthia is a retired nurse and has been involved in inner healing ministry for at least 50 years, starting when the Cathedral of Life church was being built. Both Cynthia and Gary attended Bible school, became ordained, and now cover the inner healing ministry and pastoral counseling.'}
        />
        <AppStaff
          side={"Left"}
          name={"Barry Carter"}
          title={"Outreach Pastor"}
          img={"/staff-images/Barry.jpg"}
          desc={'Involved in pastoral ministry since 1994, he received ordination through Dr. Lester Sumrall in the early 90s. His focus has been on discipleship, encompassing teaching men, couples, and fostering community connections. He has been married to his wife, Peggy, since 1976.'}
        />
        <AppStaff
          side={"Right"}
          name={"Danielle Mowery"}
          title={"Administrative Assistant"}
          img={"/staff-images/Danielle.jpg"}
          desc={'Danielle Mowery has been on staff since 2011. She works in the office, helping to oversee and organize events and services. She also enjoys helping with our youth and childrens ministries.'}
        />
        <AppStaff
          side={"Left"}
          name={"Michael Hall"}
          title={"Media Director"}
          img={"/staff-images/Mike.jpg"}
          desc={'Mike Hall, dedicated to serving since 2001, brings a harmonious blend of passion and talent to our church community. A versatile musician proficient in multiple instruments, Mikes love for music resonates through his commitment to worship. Beyond the notes, he finds joy in the art of media production, all while fostering a deep connection with his faith in Jesus. Family is at the heart of his journey, with a loving wife and five children by his side'}
        />
        <AppStaff
          side={"Right"}
          name={"Ted Anthony"}
          title={"Media Production"}
          img={"/staff-images/Ted.jpg"}
          desc={'Ted Anthony has been part of Synergy Churchs music ministry since 2009, intertwining his love for music with his worship team role. In 2020, he married Ashley, adding immense joy to his life. Besides crafting worship melodies, Ted is dedicated to content creation and music production, amplifying the ministrys impact. Aspiring to extend faith beyond the church, he aims to inspire and uplift through his roles as an author and composer. Teds commitment to artistic expression and spiritual service reflects his dedication to a purpose-driven life.'}
        />
      </div>
    );
}