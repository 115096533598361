import { Link } from "react-router-dom";
import "./Navbar.scss";
import CustomDropdown from "../Dropdown/Dropdown";

export default function Navbar() {
    const aboutLinks = [
        {link: '/servicetimes', text: 'Service Times'},
        {link: '/beliefs', text: 'Beliefs'},
        {link: '/staff', text: 'Staff'},
        {link: '/locations', text: 'Locations'},
    ]

    const ministries = [
        {link: '/worship', text: 'Worship'},
        {link: '/momentum', text: 'Momentum'},
        {link: '/youth', text: 'Youth'},
        {link: '/kids', text: 'Kids'},
        {link: '/smallgroups', text: 'Small Groups'},
        {link: '/innerhealing', text: 'Inner Healing'},
        {link: '/media', text: 'Media'},
        {link: '/outreach', text: 'Outreach'},
    ]

   var navEnabled = false

   function handleClick(){
    if(navEnabled){
      navEnabled = true
      document.getElementById('mnav').classList.add('animateLeft')
    } else {
      navEnabled = false
    }
   }



  return (
    <>
      <div className="navtop" id="top-nav">

        <img className="navbar-logo" src="/images/SynergyBugWhite.png"/>

        <Link to={'/'} className="dd">Home</Link>

        <CustomDropdown displaytext={'About'} links={aboutLinks}></CustomDropdown>
        <CustomDropdown displaytext={'Ministries'} links={ministries}></CustomDropdown>

        <Link to={'/watch'} className="dd">Watch</Link>

        <Link to={'/give'} className="dd">Give</Link>
          
      </div>
    </>
  );
}
