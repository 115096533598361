import { Link } from "react-router-dom";
import './Dropdown.scss'
import { FiChevronDown } from "react-icons/fi";

export default function CustomDropdown({ displaytext, links }) {
  return (
    <div className="dropdown">
      <span>{displaytext} <i><FiChevronDown className="chev"/></i></span>
      <div className="dropdown-content">
        <div className="format">
        {links.map((link) =>
            <Link to={link.link}>{link.text}</Link> 
        )}
        </div>
      </div>
    </div>
  );
}
