import styles from "./Header.module.scss";

export default function Header({ img, video, logo, margin, isKids}) {
  var isKids = isKids;

  if (isKids != undefined){
    isKids = isKids
  } else {
    isKids = false
  }


  return (
    <div className={styles.header}>
      {img ? (
        <>
          <img class={styles.headerimg} src={img} alt="Synergy churches ministry identifiers" />
          <img style={{marginTop: margin}} class={styles.logoimg} src={logo} alt="" />
        </>
      ) : (
        <div className={styles.videoHolder}>
          <video loop muted autoPlay className={styles.bgvideo} playsInline>
            <source src={"/videos/" + video} type="video/mp4" />
          </video>
          

          {isKids === false ? 
          <img className={styles.videoimg}  src={logo} alt="" /> : 
          <img className={`${styles.videoimg} ${styles.isKids}`}  src={logo} alt="" />
         }

        </div>
      )}
    </div>
  );
}
