import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <>
      <div className="footer-bg">
        <div className="footer-content">
          <div className="left-footer f-text">
            <p className="f-title">About us</p>
            <p>
              Synergy Church, lead by Senior Pastor Dana Gammill, is a
              contemporary, non-denominational, family oriented church and
              offers programs for all ages. We are reaching our area for the
              gospel of Jesus Christ. The body of Synergy Church is a diverse
              multicultural group of believers with the desire for the move of
              the Holy Spirit to be poured out. We welcome you to browse our
              website, check out the ministry areas that we offer and find out
              what Synergy Church is all about. If you’re looking for a church
              that has a heart for worship and an exciting and relevant Word for
              this generation then we invite you to visit one of our services.
            </p>
          </div>

          <div className="mid-footer f-text">
            <p className="f-title">Synergy Church Network</p>
            <a id="br" className="church-button" href="https://www.burningriverchurch.net/" target="_blank">
              Burning River
            </a>
            <a id="tgt" className="church-button" href="https://www.trinitybrotherdave.org/" target="_blank">
              Trinity Gospel Temple
            </a>
            <a id="tinn" className="church-button" href="https://www.theinnatbeldenvillage.com/" target="_blank">
              The Inn at Belden Village
            </a>
            <p className="f-title">RealLife Leaders</p>
            <a className='church-button' href="https://drive.google.com/drive/folders/1j_xouYs-kH9DBK9EhnaFF1ST6UvWIIwA?usp=drive_link" target="_blank">
               Login 
            </a>
          </div>

          <div className="right-footer f-text">
            <p className="f-title">Contact us!</p>
            <Link className='church-button' to={"https://docs.google.com/forms/d/e/1FAIpQLSdvbmifYalDiwJfaXs5ItIYZzKHULk9JBVMuBRbyW8D9Bh37w/viewform?embedded=true"} target="_blank" referrerPolicy="no-referrer">Contact</Link>
          </div>
        </div>
      </div>
    </>
  );
}
