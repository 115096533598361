import Header from "../../Components/Header/Header";
import ThreeWay from "../../Components/MinistryComponent/MinistryComponent";
import "./Youth.scss";

export default function Youth() {
  return (
    <div>
      <Header
        video={"camp.mp4"}
        logo={"./images/HorizontalSyLogo.png"}
      />
      <ThreeWay
        pastorTitle={"Youth Pastors"}
        pastorName={"Hunter & Alison Fowler"}
        pastorImage={"/staff-images/Hunter_Allison.jpg"}
        missionText={
          <>
            <p>The goal of the Synergy Youth Ministry is to reveal the passion and purpose of Jesus Christ for their lives and teach the full living Word of God as their foundation and center core of their existence.</p><p>Geared for young people from ages 12-18, and/or grades six through twelve. We get together each Wednesday night at 6:30, at Trinity Gospel Temple for a high energy, worship-centered encounter with a loving Heavenly Father. It’s an opportunity to be totally real and transparent, but requires a willingness and determination to be transformed.</p><p>Our youth look forward to several events each year including our ever increasingly popular summer camp. We laugh, cry, and grow together.</p>
          </>
        }
        meetingImage={"/images/Youth.jpg"}
      />
    </div>
  );
}
