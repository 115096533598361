import Header from "../../Components/Header/Header";
import ThreeWay from "../../Components/MinistryComponent/MinistryComponent";
import "./InnerHealing.scss";

export default function InnerHealing() {
  return (
    <div>
      <Header img={"./images/innerhealing.png"} logo={"./images/HZ_SC_Logo.png"} />
      <ThreeWay
        pastorTitle={"Inner Healing Pastor"}
        pastorName={"Cynthia Stock"}
        pastorImage={"/staff-images/Cynthia.jpg"}
        missionText={
          <>
            <p>Many people, because of trauma, abuse, neglect or rejection…die emotionally and mentally at the age that the trauma happened or peaked. This locks us into the past. No amount of nagging or scorn can bring us out. We must walk back with Jesus in our minds to the trauma and be RESCUED OUT…brought back to life…set free. THIS IS WHAT INNER HEALING IS ALL ABOUT!</p><p>Re-creative healing is focusing on: “What did God have for me before the fall of man?” What is normal? What was I supposed to receive as a child? Jesus is alive and waiting to heal our minds and emotions. Only He can go into our minds…to heal…to restore…to resurrect us where we died emotionally and re-create our past…to recreate and restore the ruined and wasted places inside of us.</p><p>If you are interested in Inner Healing, please call the church office to set up an appointment.</p>
          </>
        }
        meetingImage={"/images/Healing.jpeg"}
      />
    </div>
  );
}
