import "./Home.scss";
import { Link } from "react-router-dom";
import { Carousel } from "antd";

export default function Home() {
  return (
    <>
      <div className="header">
        <video loop muted autoPlay className="fullscreen-bg__video" playsInline>
          <source src="videos/churchflyover.mp4" type="video/mp4" />
        </video>

        <img className="sc-img" src="/images/HZ_SC_Logo.png" alt="" />

        <p className="header-subtile">
          "To know the Blesser. To Receive the Blessing. To Bless our World."
        </p>

        <div className="header-buttons">
          <Link className="rounded-btn-template-home" to={"/beliefs"}>
            Learn More
          </Link>
        </div>
      </div>

      <div className="latest-news">
        <p class="news-title">LATEST NEWS</p>

        <div className="latest-content">
          <div className="test-div">
            <Carousel autoplay className="carouselElement">
              <div>
                <img
                  src="/AnnouncementImages/An1.jfif"
                  alt="Announcement"
                  className="carousel-img"
                />
              </div>
              <div>
                <img
                  src="/AnnouncementImages/An2.jfif"
                  alt="Announcement"
                  className="carousel-img"
                />
              </div>
              <div>
                <img
                  src="/AnnouncementImages/An3.jfif"
                  alt="Announcement"
                  className="carousel-img"
                />
              </div>
              <div>
                <img
                  src="/AnnouncementImages/An4.jfif"
                  alt="Announcement"
                  className="carousel-img"
                />
              </div>
              <div>
                <img
                  src="/AnnouncementImages/An5.jfif"
                  alt="Announcement"
                  className="carousel-img"
                />
              </div>
              <div>
                <img
                  src="/AnnouncementImages/An6.jfif"
                  alt="Announcement"
                  className="carousel-img"
                />
              </div>
            </Carousel>
          </div>

          <div className="squares">
            <Link target="_blank" to={'https://forms.gle/5vLskXM6bLuyNEYWA'} className="square-link">
              <img
                className="square-image"
                src="/images/getInvolvedButton.jpg"
                alt="Get involved at synergy church today!"
              />
            </Link>
            <Link to={'/give'} className="square-link">
              <img
                className="square-image"
                src="/images/waystogive.jpg"
                alt="Get involved at synergy church today!"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="sermons-bg">
        <div className="sermons-content">
          <p className="sermon-title">LATEST SERMON</p>

          <div className="sermon-display">
            <div className="ServiceTimePic">
              <img
                src="/images/ServiceTimePic.jpg"
                alt="Serivce times for synergy church ministries, including Pastor Dana and Nan Gammill"
              ></img>
            </div>

            <iframe
              class="sermon-video"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/videoseries?list=PLjT6pKHmJ0_74cqE2W_9SrHT8PCn7l-_m"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className="socials-bg">
        <div className="socials-content">
          <p className="section-title">FOLLOW US ON SOCIAL MEDIA</p>

          <div className="socials-container">
            <a
              href="https://twitter.com/cathedrallife"
              target="_blank"
              className="social"
              rel="noreferrer"
            >
              <img src="images/twitter.png" alt="" />
            </a>

            <a
              href="https://www.instagram.com/colcantonoh/"
              target="_blank"
              className="social"
              rel="noreferrer"
            >
              <img src="images/instagram.png" alt="" />
            </a>

            <a
              href="https://www.facebook.com/CathedralOfLifeOh"
              target="_blank"
              className="social"
              rel="noreferrer"
            >
              <img src="images/facebook.webp" alt="" />
            </a>

            <a
              href="https://www.youtube.com/user/TrinityGospelTemple"
              target="_blank"
              className="social"
              rel="noreferrer"
            >
              <img src="images/yt.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
