import Header from "../../Components/Header/Header";
import "./Beliefs.scss";

export default function Beliefs() {
  return (
    <div>
      <Header img={"./images/wwb.png"} logo={"./images/HZ_SC_Logo.png"} />
      <div class="beliefs-content">
        <div class="btext-inside">
          <p class="bsection-title">Cathedral of Life</p>
          <p>
            is founded upon the Word of God, and birthed by His Spirit and
            endeavors to fulfill the great commission in our world. Matthew
            28:18-20 Then Jesus came to them and said, “All authority in heaven
            and on earth has been given to me. Therefore go and make disciples
            of all nations, baptizing them in the name of the Father and of the
            Son and of the Holy Spirit, and teaching them to obey everything I
            have commanded you. And surely I am with you always, to the very end
            of the age.” Although our methods of communicating the truths of
            God’s Word vary from time to time, our message remains unchanged. As
            you read these statements of faith, I hope you will be encouraged by
            the tenets we hold near and dear at Cathedral of Life.
          </p>
          <br />
          <p class="bsection-title">The Bible</p>
          <p>
            We believe the Bible, consisting of the sixty-six books of the Old
            and New Testaments, was uniquely and fully inspired by the Holy
            Spirit of God without contradiction or error, and, as originally
            written, does not only contain and convey the Word of God, but IS
            the very Word of God. It is the supreme and final authority in all
            matters on which it addresses. (Deuteronomy 4:1-2; Psalm 19:7-11;
            119; Proverbs 30:5,6; Isaiah 8:20; Luke 24:25-27,44-45; John
            5:39-47; 12:48; 16:7-14; 17:17; Romans 3:4; 15:4; Ephesians 6:17; II
            Timothy 3:16-17; I Peter 1:23; II Peter 1:19-21; Revelation 22:19)
          </p>
          <br />
          <p class="bsection-title">The Trinity – One True God</p>
          <p>
            We believe there is one, and only one, living and true God. One
            substance eternally existing in three equally infinite, excellent
            and admirable Persons: the Father, the Son, and the Holy Ghost,
            equal in every divine perfection. (Genesis 1:1; 17:1; Exodus 15:11;
            20:2-3; Psalm 83:18; 90:2; 147:5; Matthew 28:19; Mark 12:30; John
            4:24; 10:30; 15:26; Acts. 5:3-4; I Corinthians 2:10,11; 8:6; 12:4-6;
            II Corinthians 13:14; Ephesians 2:18; 4:6; Philippians 2:5-6;
            Colossians 1:16; I Timothy 1:17; I John 5:7; Revelation 4:11)
          </p>
          <br />
          <p class="bsection-title">God The Father</p>
          <p>
            We believe God is great. An infinite, intelligent Spirit, the Maker
            and Supreme Ruler of heaven and earth. He is all powerful, all
            knowing, ever present, unchanging, completely worthy of our trust,
            honor, confidence and love, and above all, holy. It is in Him that
            we live, move and exist. God is good: He is our Father. He is
            loving, compassionate, and faithful to His people and His promises.
            (Exodus 3:14; Numbers 23:19; Leviticus 11:44-45; 19:2; Psalm 11:4-6;
            Malachi 3:6 John 3:16; 4:24; 5:26; 14:1; Acts 17:28; Romans 3:3-4)
          </p>
          <br />
        </div>

        <div class="black-div-bg">
          <div class="btext-inside">
            <p class="bsection-title">The Person and work of Jesus Christ</p>
            <p>
              We believe the OT promised that the coming Savior would come from
              the seed of the woman (Eve) to destroy Satan (Genesis 3:15); that
              He would be born of a virgin (Isaiah 7:14); that He would come as
              King from the tribe of Judah (Genesis 49:10) through the line of
              David to reign over Israel and the world (2 Samuel 7:12,13; Isaiah
              9:6,7; Micah 5:2; Zechariah. 14); that He would be sinless and die
              as a substitute for the sins of mankind (Psalm 22:1, 6-7, 14-18;
              Isaiah 52:13,14; 53); and that He would rise from the dead (Psalm
              16:10,11; Micah 7:7,8). This promised Messiah (Anointed One of
              God) would be both God (Isaiah 9:6; Micah 5:2; Matthew 1:23; John
              20:28; Philippians 2:6; Colossians 1:16) and man (Gen. 3:15;
              Matthew 10:30, 33). We believe that the promised Messiah of the OT
              has come in the person of Jesus Christ (Messiah) (Matthew 1; John
              1:1-18; Romans 1:1-4; 2 Tim. 2:8; Hebrews 1-3). The NT testifies
              that Jesus Christ, being both God and man, (John 1:1,14;
              Revelation 1:17,18) has fulfilled the promise of the OT by being
              born of the virgin Mary by the Holy Spirit (Luke 1:26-38); that He
              attested to His Messianic claims by performing miracles (John
              10:37-38; 12:37); that He died as a substitute for man’s sins and
              rose physically from the grave on the third day in order to
              appease God’s wrath against mankind and satisfy His justice in
              reconciling man back into a relationship with a Holy God (Romans
              5:8-21; 1 Corinthians 15:3-8; 2 Corinthians 5:18-21;1 Peter 2:24;
              3:18); and that He ascended to heaven to sit at the right hand of
              the Father waiting until the time of His return to earth to reign
              over the nation of Israel and the world (Ps. 2: 110:1-2; Zechariah
              12:7-10; Matt. 25:31-46; Mark 6:19; Acts 1:9-11; 3:20-21; Heb.
              1:3; 8:1; 9:28; 12:2).
            </p>
            <br />

            <p class="bsection-title">The Person and work of The Holy Spirit</p>
            <p>
              We believe the Holy Spirit is a divine Person; that His presence
              assures us of our relationship with Christ; that He was active in
              the creation; that in His relation to the unbelieving world, He
              convicts of sin, of judgment, and righteousness; that He bears
              witness to the Truth of the Gospel in preaching and testimony;
              that He guides believers into all truth; that He exalts Christ,
              gives us spiritual gifts, regenerates, seals, guides, teaches,
              comforts, witnesses, indwells, seals, sanctifies and makes us more
              like Christ. (Genesis 1:1-3; Psalms 51:11; 139:7 ff.; Isaiah
              61:1-3; Joel 2:28-32; Matthew 3:11; 28:19; Mark 1:8&10; Luke 1:35;
              3:16; 4:1; 11:13; 12:2; 24:49; John 1:33; 3:5-6; 14:16,17,26;
              15:26-27; 16:7-14,13; Acts 1:8; 2:1-4; 5:30-32; 11:16; 13:2;
              Romans 8:9-16,26-27; 1 Corinthians 3:16; Ephesians 1:13-14; II
              Thessalonians 2:13; Hebrews 9:14; I Peter 1:2; 2 Peter 1:21;
              Revelation 22:17)
            </p>
            <br />

            <p class="bsection-title">Mankind</p>
            <p>
              We believe man was created in the image and likeness of God, to
              fellowship with Him and give Him glory. Human beings are therefore
              the supreme object of God’s creation, and thus distinct in kind
              from all other life on earth. Mankind was created in innocence,
              but due to Adam and Eve’s sinful disobedience, man’s thoughts,
              motives, emotions, words and actions – his nature – is corrupted,
              and he is thus totally unable to please or attain a right
              relationship with God through his own effort, and therefore is
              under just condemnation without defense or excuse. The only remedy
              for mankind’s predicament is redemption through faith in Jesus
              Christ. (Genesis 1:26-30; 2:7, 18-22; 3;; Ezekiel 18:19-20; Psalms
              8:3-6; 32:1-5; 51:5; Isaiah 6:5; Jeremiah 17:5; Acts 17:26-31;
              Romans 1:18-32; 3:10-19; 5:6,12,19; 7:14-25; 1 Corinthians
              1:21-31; 15:19, 21-22; Galatians 3:22; Ephesians 2:1-3; Colossians
              1:21-22; 3:9-11)
            </p>
            <br />
          </div>
        </div>

        <div class="btext-inside">
          <p class="bsection-title">Salvation</p>
          <p>
            We believe salvation is solely a work of God’s grace through the
            blood of Jesus Christ, shed on the cross; received exclusively
            through faith in the death and resurrection of Jesus Christ as
            sufficient payment for their sin, and never by works of human merit.
            We believe that in order to be saved, sinners must be born again;
            that the new birth is a new creation in Christ Jesus; that it is
            instantaneous and not a process – It is the deliverance from the
            penalty, power and presence of sin (Romans 6:11-12; 8:1, 31).
            Christ’s sacrifice was the voluntary substitution of Himself in the
            sinner’s place, the just dying for the unjust. He is in every way
            qualified to be a suitable, a compassionate, and an all-sufficient
            Savior. Salvation is through personal faith in the Lord Jesus
            Christ; all who receive Jesus Christ are regenerated by the Holy
            Spirit and become the children of God. True salvation will be
            manifested by a changed life. Nothing prevents the salvation of the
            greatest sinner on earth but their willful and voluntary rejection
            of the gospel. Upon physical death this rejection brings eternal,
            conscious separation from the Lord and awaits the resurrection of
            his body to everlasting judgment and condemnation. (Isaiah 1:18;
            53:5-6; 55:7; Matthew 1:21; 18:11; 27:22-28:6; Luke 1:68-69;
            2:28-32; John 1:12; 3:16; 5:24; 10:18; Acts 2:21; 4:12; 15:11;
            16:30-31; 20:20-21; Rom 1:16-18; 3:21-26; 5:8-10; 6; 10:9-10; I
            Corinthians 1;18; 15:3; II Corinthians 5:17-21; Galatians 1:4; 2:20;
            3:12; Ephesians 2:8-10; 4:22-24; Philippians 2:7,8, 12-13; 2 Timothy
            2:19; Titus 2:11-14; Titus 3:4-7; Hebrews 2:14-18, 7:25, 9:12-15,
            24-28; 12:2; I Peter 2:24; 3:18; I John 2:2; 4:10; 5:12; Revelation
            3:20; 20:15; 21:8)
          </p>
          <br />
          <p class="bsection-title">Baptism and the Lord’s Supper</p>
          <p>
            We believe Christian baptism is the immersion in water of the
            believer in the name of the Father, Son, and Holy Spirit, should be
            observed as commanded in the scriptures, as an outward sign of what
            God has already done in the individual’s life through faith in the
            effectual and all powerful work of Christ on the cross. We believe
            baptism is not a “sacrament” that imparts saving grace. We are not
            saved by baptism, but by faith in Jesus Christ. Water Baptism is by
            the authority of the local church to show forth, in a solemn and
            beautiful emblem, our faith in the crucified and risen Savior. It is
            symbolic of our death to sin and resurrection to a new life.The
            Lord’s Supper is a commemoration of the death of the Lord and is
            done in remembrance of Him by all believers “until He comes”, always
            preceded by solemn self-examination. (Matthew 3:6; 28:19,20; John
            3:23; Acts 2:41,42; 8:36-39; 10:47-48; Romans 6:3-5; I Corinthians
            11:23-34; Colossians 2:12)
          </p>
          <br />
          <p class="bsection-title">Baptism in the Holy Ghost</p>
          <p>
            We believe all believers are entitled to and should expect and
            earnestly seek the baptism in the Holy Ghost and fire, a gift from
            God as promised by the Lord Jesus Christ to all believers and is
            received following salvation. This experience is accompanied by the
            evidence of the initial, physical sign of speaking in other tongues
            as the Holy Spirit Himself gives utterance and by the subsequent
            manifestation of spiritual power in public testimony and service.
            (Matthew 3:11, Luke 3:16; John 14:16-17, Acts 1:4-9; 2:4; 2:38-39;
            10:44-46; 19:1-8; 1 Corinthians 12:1-31).
          </p>
          <br />
        </div>

        <div class="black-div-bg">
          <div class="btext-inside">
            <p class="bsection-title">The Church</p>
            <p>
              We believe that the church of Christ is a congregation of baptized
              believers associated by a covenant of faith and fellowship of the
              gospel; observing the ordinances of Christ; governed by His laws;
              and exercising the gifts, rights, and privileges invested in them
              by His Word. We believe the true mission of the church is found in
              the Great Commission: seeking to extend the Gospel to the ends of
              the earth. The Church operates under the Lordship of Christ and
              each member is responsible and accountable to Christ as Lord. We
              hold that the local church has the absolute right of
              self-government, free from the interference of any hierarchy of
              individuals or organizations; and the one and only Superintendent
              is Christ through the Holy Spirit. We believe the New Testament
              speaks also of the church as the Body of Christ, which includes
              all of the redeemed of all the ages, believers from every tribe,
              and tongue, and people, and nation. (Matthew 16:18; 28:19-20; Acts
              2:41,42; 6:5-7; 14:23; 15:22,23; 20:17-28; I Corinthians 5:11-13;
              6:1-3; 16:1; II Corinthians 8:23,24; 12:4; Ephesians 1:22,23;
              2:19-22; 4:11; 5:23,24; Colossians 1:18, I Timothy 3:1-13; I Peter
              5:1-4)
            </p>
            <br />

            <p class="bsection-title">Prosperity</p>
            <p>
              We believe in total prosperity for the believer. Spiritual (John
              3:3-11; Romans 10:9), Mental (2 Timothy 1:7), Physical (Isaiah
              53:4-5; 1 Peter 2:24), Financial (3 John 1:2; Malachi 3:10-11) and
              Social (Proverbs 3:4)
            </p>
            <br />

            <p class="bsection-title">
              The Resurrection and Return of Christ and Related Events
            </p>
            <p>
              We believe in and accept the sacred Scriptures, upon these
              subjects, at their face and full value. Of the Resurrection we
              believe Christ rose bodily, “the third day according to the
              Scriptures,” and that He ascended “to the right hand of the throne
              of God.” He alone is our “merciful and faithful high priest in
              things pertaining to God”; “this same Jesus which was taken up
              from you into Heaven shall so come in like manner as you have seen
              Him go into Heaven… “bodily, personally, and visibly; that the
              “dead in Christ shall rise first”; that the living saints “shall
              all be changed in a moment, in the twinkling of an eye, at the
              last trump”; “that the Lord God shall give unto Him the throne of
              His Father, David”; and “that Christ shall reign a thousand years
              in righteousness until He hath put all enemies under His feet.”
              After this, there will be a new heaven and a new earth. (Psalm
              72:8; Isaiah 9:6,7; 11:4,5; 32:1; Matthew 24:27; 25:13; 28:6,7;
              Mark 16:6,19; Luke 1:32;24:2-6,39,51; John 14:3; 20:27; Acts
              1:9,11; I Corinthians 15:4,25; 15:51-52; Philippians 3:20,21; I
              Thessalonians 4:16-18; I Timothy 2:5; Hebrews 2:17; 5:9,10; 8:1,6;
              12:2; James 5:8; I John 2:1; Revelation 3:21; 19:11-16; 20:1-7,21)
            </p>
            <br />
          </div>
        </div>

        <div class="btext-inside">
          <p class="bsection-title">Eternity</p>
          <p>
            We believe man was created to exist forever. He will exist either
            eternally separated from God by sin or in union with God through
            forgiveness and salvation through Jesus Christ. To be eternally
            separated from God is Hell; eternal separation from God and
            punishment in the lake of fire. To be eternally in union with Him is
            Heaven; eternal, endless joy and elation of Christians in the
            presence of God. (John 3:16, 36; Romans 6:23; Hebrews 9:27; 1 John
            2:25; 5:11-13; Revelation 19:20; 20:10-15)
          </p>
          <br />
        </div>
      </div>
    </div>
  );
}
