import styles from "./StaffComponent.module.scss";

export default function AppStaff({ side, img, name, title, desc }) {
  return(
    <div className={`${styles.outsideStaffComponent} ${side === "Right" ? styles.darkbg : ''}`}>
        <div className={`${styles.staffContent} ${side === "Right" ? styles.reverseLayout : ''}`}>

            <div className={styles.imgContent}>
                <h3 className={`${styles.title} ${side === "Right" ? styles.whiteText : styles.blackText}`}>{title}</h3>
                <h2 className={`${styles.name} ${side === "Right" ? styles.whiteText : styles.blackText}`}>{name}</h2>
                <img className={`${styles.teamPicture} ${side === "Right" ? styles.imgShadowReversed : styles.imgShadow}`} src={img}></img>
            </div>

            <div className={styles.textContent}>
                <h3 className={`${styles.about} ${side === "Right" ? styles.whiteText : styles.blackText}`}>About</h3>
                <p className={`${styles.textDescription} ${side === "Right" ? styles.whiteText : styles.blackText}`}>{desc}</p>
            </div>

        </div>
    </div>
  );
}
