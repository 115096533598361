import Header from "../../Components/Header/Header";
import "./Give.scss";

export default function Give() {
  return (
    <div>
      <Header img={"./images/normalheader.jpg"} logo={"./images/HZ_SC_Logo.png"} />

      <div class="give-main">
        <a class="give-btn" href="https://secure.myvanco.com/L-Z9YC" target="_blank" rel="noopener noreferrer">GIVE ONLINE</a>
        <p class="thank-you">Thank you for your gift!</p>
        <p class="verse-title">Psalm 126:3</p>
        <p class="indented-text">The LORD has done great things for us, and we are filled with joy.</p>
        <p class="para-graph">We appreciate your love for God and His people. Your gift is the fuel for this ministry to deliver the message of hope. You are giving to help change lives, restore relationships and transform communities in Ohio and beyond. The Lord is doing great things in and through us! He has even greater things for us to do in the coming year. With your help we will continue to spread the Good News of Jesus Christ. Consider taking advantage of one of these easy options:</p>
        <div class="three-way">
            <div class="tgt">
                <p class="tgt-title">Text To Give (TGT)</p>
                <p class="tgt-text">Text the amount to <a class="link" href="tel:+1-866-756-7501">1-866-756-7501</a></p>
            </div>
            <div class="mail-in">
                <p class="mail-to-title">Mail in your Donation</p>
                <p class="mail-to-text">Mail to the <a class="link" href="locations">38th street campus</a> and we will apply your gift.</p>
            </div>
        </div>
    </div>
    </div>
  );
}
