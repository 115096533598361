import Header from "../../Components/Header/Header";
import "./Locations.scss";

export default function Locations() {
  return (
    <div>
      <Header
        img={"./images/normalheader.jpg"}
        logo={"./images/HZ_SC_Logo.png"}
      />

      <div class="locations-div">
        <div class="location">
          <p class="location-title">38TH Street Campus</p>
          <p class="locaiton-site">

          </p>
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.343762422062!2d-81.42279262352538!3d40.8423758296232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8836d129b08cf35b%3A0x115ce5a4bfa132f7!2sCathedral%20of%20Life!5e0!3m2!1sen!2sus!4v1681519787566!5m2!1sen!2sus"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <img
            class="campus-img"
            src="images/38th_Building.jpg"
            alt="Display of the Synergy Church main campus"
          />
        </div>
        <div class="location">
          <p class="location-title">Trinity Campus</p>
          <p class="locaiton-site">
            Vist the Trinity site at{" "}
            <a
              class="link"
              href="https://www.trinitybrotherdave.org/"
              target="_blank"
            >
              trinitybrotherdave.org
            </a>
          </p>
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3020.3126760713126!2d-81.3948522233878!3d40.79912233227668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8836d082628afb83%3A0x74dee8d173112247!2sTrinity%20Gospel%20Temple!5e0!3m2!1sen!2sus!4v1681521123435!5m2!1sen!2sus"
            width="600"
            height="450"

            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <img
            class="campus-img"
            src="images/Trinity_Building.jpg"
            alt="Trinity Campus"
          />
        </div>
        <div class="location">
          <p class="location-title">Burning River Church</p>
          <p class="locaiton-site">
            Vist Burning Rivers site at{" "}
            <a
              class="link"
              href="https://www.burningriverchurch.net/"
              target="_blank"
            >
              burningriverchurch.net
            </a>
          </p>
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.052731315755!2d-81.48289592339788!3d40.51832554944881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8836e2f99962ccc3%3A0xf3e232cbbce5656e!2sBurning%20River%20Church!5e0!3m2!1sen!2sus!4v1681521151407!5m2!1sen!2sus"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
