import Header from "../../Components/Header/Header";
import "./Watch.scss";

export default function Watch() {
  return (
    <div>
      <Header img={"./images/normalheader.jpg"} logo={"./images/HZ_SC_Logo.png"} />

      <div class="watch-div">
       <div class="video">
            <p class="title">9:00AM Service</p>
            <iframe class="sermon-video" src="https://www.youtube.com/embed/videoseries?si=esrRpJqPuEKnLmP-&amp;list=PLjT6pKHmJ0_74cqE2W_9SrHT8PCn7l-_m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
       </div>

       <div class="video">
            <p class="title">10:30AM Service</p>
            <iframe class="sermon-video" src="https://www.youtube.com/embed/videoseries?si=Raa3FxopkH32vRQ4&amp;list=PLjT6pKHmJ0_6bOsxm6QzqkPnqdh-OeYP_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
       </div>

       <div class="video">
            <p class="title">6:00PM Service</p>
            <iframe class="sermon-video" src="https://www.youtube.com/embed/videoseries?si=sVDnlPgi8Ve9JDVe&amp;list=PLjT6pKHmJ0_552Exxio3972aJ7nNi0DY6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
       </div>
    </div>
    </div>
  );
}
