import styles from "./MinistryComponent.module.scss";

export default function ThreeWay({pastorTitle, pastorName, pastorImage, missionText, meetingImage}) {
  return (
    <div className={styles.threewaycontent}>
      <div className={styles.minpastorscontent}>
        <h3>{pastorTitle}</h3>
        <h2>{pastorName}</h2>
        <img src={pastorImage}/>
      </div>

      <div className={styles.mininformation}>
        <h2>Our Mission</h2>
          {missionText}
      </div>

      <div className={styles.minexamplepic}>
        <img src={meetingImage}></img>
      </div>
    </div>
  );
}
