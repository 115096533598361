import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './Pages/Home/Home';
import ServiceTimes from './Pages/ServiceTimes/ServiceTimes';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Beliefs from './Pages/Beliefs/Beliefs';
import Staff from './Pages/Staff/Staff';
import Locations from './Pages/Locations/Locations';
import Outreach from './Pages/Outreach/Outreach';
import Worship from './Pages/Worship/Worship';
import Momentum from './Pages/Momentum/Momentum';
import Youth from './Pages/Youth/Youth';
import Kids from './Pages/Kids/Kids';
import SmallGroups from './Pages/SmallGroups/SmallGroups';
import InnerHealing from './Pages/InnerHealing/InnerHealing';
import Media from './Pages/Media/Media';
import Give from './Pages/Give/Give';
import Watch from './Pages/Watch/Watch';

function App() {

  return (
    <div className='all-content-in-app'>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/servicetimes" element={<ServiceTimes/>} />
        <Route path="/beliefs" element={<Beliefs/>} />
        <Route path="/staff" element={<Staff/>} />
        <Route path="/locations" element={<Locations/>} />
        <Route path="/worship" element={<Worship/>} />
        <Route path="/outreach" element={<Outreach/>} />
        <Route path="/momentum" element={<Momentum/>} />
        <Route path="/youth" element={<Youth/>} />
        <Route path="/kids" element={<Kids/>} />
        <Route path="/smallgroups" element={<SmallGroups/>} />
        <Route path="/innerhealing" element={<InnerHealing/>} />
        <Route path="/media" element={<Media/>} />
        <Route path="/watch" element={<Watch/>} />
        <Route path="/give" element={<Give/>} />
      </Routes> 
    
      <Footer/>
    </div>
  );
}

export default App;
