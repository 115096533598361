import Header from "../../Components/Header/Header";
import "./Outreach.scss";

export default function Outreach() {
  return (
    <div>
      <Header img={"./images/normalheader.jpg"} logo={"./images/HZ_SC_Logo.png"} />
      <div class="outreach-content">
        <div class="outeach-black-bg">
          <div class="outeach-text-inside">
            <p class="outeach-title">Benevolence</p>
            <p>
              Cathedral of Life regularly helps individuals, families & churches
              in need. As a church, we financially support every one of these
              ministries and outreaches but we can’t do it alone. Click here to
              partner with us in our efforts to give hope to our community, our
              nation and our world. Remember what you do unto the least of these
              – you’ve done unto Him!
            </p>
            <br />

            <p class="outeach-title">Missions Abroad</p>
            <p>
              Currently Cathedral of Life financially supports the following
              missions around the world:
            </p>
            <ul className="outreach-list">
              <li>Dr. Ben Oruma – Nigeria</li>
              <li>Victor Zapata – Mexico</li>
              <li>John Withington, FCFI – India</li>
              <li>
                Ken Gaub, Ken Gaub Ministries – Israel & Radio Ministries around
                the world
              </li>
            </ul>
            <br />

            <p class="outeach-title">Mission Sundays</p>
            <p>
              On the last Sunday of each month, we turn our focus a specific
              region of the world. We pray over conditions such as poverty,
              sickness/disease, crime, hunger and any other prayer issues
              specific to that area and collect a special offering to contribute
              toward missions in that region.
            </p>
            <br />
          </div>
        </div>

        <div class="outeach-text-inside">
          <p class="outeach-title">Samaritan Sunday / Trinity Dream Center</p>
          <p>
            Every 1st Sunday of the month we have the opportunity to bless the
            less fortunate in our community. By bringing in necessities and
            non-perishable, basic food items (no glass please), you help keep
            our food pantry stocked for those in need as well as support our
            joint efforts with the Dream Center at Trinity Gospel Temple. In
            joining with the Dream Center, we are concentrating our efforts on a
            strategic outreach partner who is already having tremendous impact
            in our city and across the world. Every month your faithful
            donations and contributions combined with those of Trinity Gospel
            Temple, feed over 400 families a week.
          </p>
          <br />
          <p class="outeach-title">Thanksgiving & Christmas Baskets</p>
          <p>
            Every year baskets full of food are delivered to families in our
            community that cannot afford dinner for the Thanksgiving & Christmas
            holidays.
          </p>
          <br />
          <p class="outeach-title">The Word for You Today Devotionals</p>
          <p>
            Every quarter Cathedral of Life distributes 1,500 daily devotionals
            throughout the community.
          </p>
          <br />
        </div>

        <div class="outeach-black-bg">
          <div class="outeach-text-inside">
            <p class="outeach-title">Keys for Successful Living Devotional</p>
            <p>
              is circulated in 100,000 homes weekly in the Canton Repository
              every Friday and Sunday. As well as posted on Facebook, Twitter
              and on Pastor Dana’s blog.
            </p>
            <br />

            <p class="outeach-title">Successful Living</p>
            <p>
              Television outreach ministry is on two programs in 200,000 homes
              weekly and radio outreach ministry covers all of Northeastern Ohio
              with potentially millions of listeners every weekday, three times
              a day on two stations
            </p>
            <br />

            <p class="outeach-title">
              Catch Pastor Dana weekly on the following broadcasts:
            </p>
            <p>TBN “Successful Living” Thursdays at 11:30a.m.</p>
            <p>Massillon Cable Channel 21 Sundays at 10:30a.m.</p>
            <p>WHK 1220 AM weekdays at 12:30p.m.</p>
            <p>WNPQ 95.9 FM weekdays at 12:00p.m.</p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
