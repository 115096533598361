import Header from "../../Components/Header/Header";
import ThreeWay from "../../Components/MinistryComponent/MinistryComponent";
import "./Media.scss";

export default function Media() {
  return (
    <div>
      <Header img={"./images/normalheader.jpg"} logo={"./images/Synergy_Media_Horizontal.png"} />
      <ThreeWay
        pastorTitle={"Media Director"}
        pastorName={"Mike Hall"}
        pastorImage={"/staff-images/Mike.jpg"}
        missionText={
          <>
            <p>The Synergy Media team is all about creating an environment conducive to the moving of the Holy Spirit. Our mission is to utilize technology to support the delivery of The Gospel. Encompassing Lights, Cameras, Sound, Video, and any other tech related aspect of our Worship Experiences. We are always looking for Servant Hearted, Hard Working people to add to the team. We are open to training people who are willing to learn!</p>
          </>
        }
        meetingImage={"/images/Media.jpg"}
      />
    </div>
  );
}
